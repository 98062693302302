import dayjs from 'dayjs';

import { BusinessMonthRangeOutput, Day, DayObj, Month } from './types';
import { api } from '../../index';
import { convertArrayObjectKeysToLanguageCase } from 'helpers/utils/convertObject';

const resourceUrlUsers = '/calendar';

export const getCalendarByYear = (year: number) => api.get<Month[]>(`${resourceUrlUsers}/${year}/`);

export const getCalendarByMonth = (year: number, month: number) => api.get<Month>(`${resourceUrlUsers}/${year}/${month}/`);

/** Returns date of month start day and date of month end day */
export const getCalendarMonthRange = (userId: number, date: string) => {
  const year = dayjs(date).year();
  const month = dayjs(date).month() + 1;

  const errorObject = {
    404: {
      hidden: true,
    },
  };

  return api.get<BusinessMonthRangeOutput>(`/calendar/${year}/${month}/range/`, undefined, { errorObject });
};

/** Allows searching business month range by a day (date) */
export const getBusinessMonthByDate = (date: string) => {
  const errorObject = {
    404: {
      hidden: true,
    },
  };

  return api.get<BusinessMonthRangeOutput>('/calendar/months/range/', { day: date }, { errorObject });
};

export const updateBusinessCalendarPeriod = (days: DayObj[], businessMonthId: number) => api.put(`${resourceUrlUsers}/months/${businessMonthId}/`, {
  business_month_id: businessMonthId,
  days: convertArrayObjectKeysToLanguageCase(days, 'snakeCase'),
});

export const updateMonth = (days: Day[], monthId: number) => api.put(`${resourceUrlUsers}/months/${monthId}/`, {
  business_month_id: monthId,
  days: days.map((day: Day) => {
    return {
      day: day.day,
      status: day.status,
      business_day_id: day.businessDayId,
    };
  }),
});
