import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Button, Space, Typography } from 'antd';
import _ from 'lodash';

import TrackerLogo from 'components/_shared/TrackerLogo/TrackerLogo';
import { Redux } from 'helpers/types/_common';
import { ReactComponent as GLogo } from 'helpers/icons/GoogleLogo.svg';
import { ReactComponent as MSLogo } from 'helpers/icons/MicrosoftLogo.svg';
import { ReactComponent as ASLogo } from 'helpers/icons/AppStore.svg';
import GPLogo from 'helpers/icons/GooglePlayStore.svg';
import { getGoogleAuthPath, getMicrosoftAuthPath } from 'V1/requests/Auth';
import useApi from 'api/hooks/useApi/useApi';
import { AuthorizationResponse } from 'api/Auth/types';

import { APP_STORE_LINK, GOOGLE_STORE_LINK } from './helpers/constants';
import s from './helpers/styles.module.sass';

const Login = () => {
  const { search } = useLocation();

  const { message } = useSelector((state: Redux) => state.login.userDataRequest);

  const [ getGooglePath, googleAuthPathResponse, loadingGooglePath ] = useApi<typeof getGoogleAuthPath, AuthorizationResponse>(getGoogleAuthPath);
  const [ getMicrosoftPath, microsoftAuthPathResponse, loadingMicrosoftPath ] = useApi<typeof getMicrosoftAuthPath, AuthorizationResponse>(getMicrosoftAuthPath);

  useEffect(() => {
    if (_.isEmpty(search)) {
      const redirectUri = `${window.location.protocol}//${window.location.host}`;

      getGooglePath(redirectUri);
      getMicrosoftPath(redirectUri);
    }
  }, []);

  const redirectByClick = (provider: string) => {
    console.log(provider)
    let redirectUri = `${window.location.protocol}//${window.location.host}/?provider=${provider}`;
    const authPathResponse = provider == "google" ? googleAuthPathResponse : microsoftAuthPathResponse
    if (authPathResponse) {
      window.location.href = authPathResponse.authorizationUrl;
    } else {
      const getPath = provider == "google" ? getGoogleAuthPath : getMicrosoftAuthPath;
      getPath(redirectUri).then((res) => {
        window.location.href = res.authorizationUrl;
      });
    }
  };

  const redirectToGoogle = () => {
    redirectByClick("google");
  };

  const redirectToMicrosoft = () => {
    redirectByClick("microsoft");
  };

  const renderAuthMessage = () => {
    if (!message) {
      return null;
    }

    return (
      <span className={s.authMessage}>
        {message}
      </span>
    );
  };

  return (
    <div className={s.login}>
      <div className={s.loginWrapper}>
        <TrackerLogo className={s.trackerLogo} />

        <div className={s.loginPageBody}>
          <Space direction="vertical" size={20} className={s.loginPageBodyWelcome}>
            <h1>Sign in to&nbsp;proceed</h1>
            <p>Use your QS email to enter to Time Tracker</p>
          <Button
              className={s.loginPageButton}
              size="large"
              type="link"
              onClick={redirectToMicrosoft}
              loading={loadingMicrosoftPath}
              icon={<MSLogo className={s.msLogo} />}
            >
              Sign In with Microsoft
            </Button>
          <Button
              className={s.loginPageButton}
              size="large"
              type="link"
              onClick={redirectToGoogle}
              loading={loadingGooglePath}
              style={{paddingRight: "34px"}}
              icon={<GLogo className={s.googleLogo} />}
            >
              Sign In with Google
            </Button>
            
            {renderAuthMessage()}

          </Space>

          <Space className={s.loginPageFooter} size={20} direction="vertical">
            <Typography.Text>You can use mobile apps to access Time Tracker</Typography.Text>
            <Space size={20} align="start">
              <a href={APP_STORE_LINK}>
                <ASLogo className={s.appleStore} />
              </a>
              <a href={GOOGLE_STORE_LINK} className={s.googleStoreLogo}>
                <img src={GPLogo} alt="Google Play Logo" />
              </a>
            </Space>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default Login;
