import { Dayjs } from 'dayjs';

import { BaseActivityOutput, BasePartnerOutput } from 'api/Partners/types';
import { CommentEditingStates } from 'components/TimeSheet/components/Table/components/TableBody/components/UserHourComments/helpers/types';

import { Meta } from '../_request/types';

export interface GetBusinessCalendarByIdProps extends BCMonth {}

export interface GetUserHoursPaginationProps {
  orderBy?: 'id' | 'start_time';
  orderDirection?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
}

export interface GetUserHoursProps extends GetUserHoursPaginationProps {
  startDate: string;
  endDate: string;
}

export interface HoursDataToDelete {
  startCellIndex: number;
  endCellIndex: number;
  day: string;
}

export interface HoursDataToAdd extends HoursDataToDelete {
  activityId: number | undefined;
  comment?: string;
}

export interface UsersHour extends UsersHourResponse {
  cutDay?: boolean;
  originalRecordingTimeStamps?: OriginalRecordingTimeStamps;
}

export interface UsersHourResponse {
  timesheetId: number | null;
  startTime: string;
  endTime: string;
  activity: BaseActivityOutput;
  partner: BasePartnerOutput;
  hours: HoursType,
  comment: UserHourComment | null;
}

export type OriginalRecordingTimeStamps = {
  startTime: string;
  endTime: string;
};

export enum HoursType {
  Hour = 1,
  HalfHour = 0.5,
}

export interface EditableUserHourComment extends GroupedUserHourComment {
  state: CommentEditingStates;
}

export interface GroupedUserHourComment extends UserHourComment {
  startCellIndex: number;
  endCellIndex: number;
}

export interface UserHourComment {
  commentId: number | string;
  comment: string;
}

export interface UserHours {
  meta: Meta,
  objects: UsersHour[],
}

export interface BCMonth {
  businessMonthId: number | null,
  year: number,
  month: number,
  startDay: string;
  endDay: string;
  modified: boolean;
  jointDays: BCDay[];
}

export interface BCDay {
  businessDayId: number | null,
  day: string,
  status: BCStatuses,
  jointStatus: BCJointStatus;
  cutDay?: boolean,
}

export enum UserMonthStatusEnum {
  Opened = 'OPENED',
  Closed = 'CLOSED',
  NotOpenedYet = 'NOT_OPENED_YET',
}

export interface UsersBCMonth {
  userMonthStatus: UserMonthStatusEnum;
  days: UsersBCDay[];
  modified: boolean;
  year: number;
  month: number;
  businessMonthId: number | null;
  startDay: string;
  endDay: string;
}

export interface UsersBCDay extends BCDay {
  hours: number;
}

export enum BCJointStatus {
  Basic = 'BASIC',
  Additional = 'ADDITIONAL',
  Cut = 'CUT',
}

export enum BCStatuses {
  Regular = 'REGULAR',
  DayOff = 'DAY_OFF',
  Holiday = 'HOLIDAY',
}

export interface ReplaceTimesheetBody {
  userId: number;
  activityId: number;
  newActivityId: number;
  selectedDateObj: Dayjs;
}
