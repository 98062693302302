import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Button, Space } from 'antd';

import { DayType } from 'components/BusinessCalendar/helpers/types';

import S from './helpers/styles.module.sass';
import { DayProps } from './helpers/types';
import { MonthInnerType } from '../Month/components/MonthInner/helpers/types';
import { DayStatuses } from 'api/BusinessCalendar/types';

const Day: React.FC<DayProps> = (props) => {
  const { day, addingDaysMode, onDayStatusChange, onDayAmountChange, currentPeriodMonth, currentPeriodYear, monthInnerType, monthKey } = props;
  const isCurrentMonth = monthKey === 'currentMonth';
  const interactiveMode = monthInnerType === MonthInnerType.Drawer && (isCurrentMonth || !day.disabled);

  const classes = cx(S.day, { 
    [S[day.status]]: day.status,
    [S.fake]: day.dayType !== DayType.Original,
    [S.interactive]: interactiveMode,
    [S.disabled]: day.disabled,
  });
  const onClickHandler = addingDaysMode
    ? () => (onDayAmountChange && onDayAmountChange(day, currentPeriodMonth, currentPeriodYear))
    : () => (onDayStatusChange && onDayStatusChange(day, monthKey));
  const NBSP = "\xa0";
  const holidayMarker = day.status === DayStatuses.Holiday ? "*" : NBSP;
  
  const renderInteractiveDay = () => (
    <Button type="link" className={classes} onClick={interactiveMode ? onClickHandler : () => {}}>
      <Space direction="vertical">
        <span className={S.label}>{NBSP}{dayjs(day.day).format('DD')}{holidayMarker}</span>
      </Space>
      
    </Button>
  );
  
  const renderDay = () => (
    <div className={classes}>
      <span className={S.label}>{NBSP}{dayjs(day.day).format('DD')}{holidayMarker}</span>
    </div>
  );

  return interactiveMode ? renderInteractiveDay() : renderDay();
};

export default Day;
