import React, { useState, useRef } from 'react';
import { Button, Input, Select, Space } from 'antd';
import classNames from 'classnames/bind';
import _ from 'lodash';

import { ReactComponent as EnglishIcon } from 'helpers/icons/EnglishNew.svg';
import { ReactComponent as SelectIcon } from 'helpers/icons/SelectArrow.svg';
import ENGLISH_KNOWLEDGE_STATES, { ENGLISH_KNOWLEDGE_TITLE } from 'helpers/constants/Skills/EnglishKnowledgeStates';
import { ExperienceFilterNames } from 'helpers/types/Skills';
import EXPERIENCE_FILTERS from 'helpers/constants/Skills/ExpFilters';
import OCCUPATIONS from 'helpers/constants/Occupations/Occupations';
import { ReactComponent as SearchIcon } from 'helpers/icons/SearchIcon.svg';
import { ReactComponent as CloseCross } from 'helpers/icons/CloseCross18.svg';
import { ReactComponent as ShowLess } from 'helpers/icons/Buttons/ShowLess.svg';
import { ReactComponent as ShowMore } from 'helpers/icons/Buttons/ShowMore.svg';
import { ConstantType, EnglishLevelEnum } from 'helpers/types/_common';
import { SKILL_EXPERIENCES_COLOR } from 'helpers/constants/Skills/Experience';
import { EmployeeOccupationEnum } from 'helpers/constants/Occupations/types';
import EngKnowledgeLevel from 'components/_shared/EngKnowledgeLevel';
import SkillTag from 'components/_shared/SkillTag';
import selects from 'helpers/styles/components/selects.module.sass';
import inputs from 'helpers/styles/components/inputs.module.sass';
import buttons from 'helpers/styles/components/buttons.module.sass';

import { SpecialistsSkills } from '../../../../helpers/types';
import SpecifyExpButton from '../../../SpecifyExpButton';
import ExperienceField from './components/ExperienceField';
import { SpecialistSettingsProps } from './helpers/types';
import S from './helpers/styles.module.sass';
import GeneralFiltersButton from '../../../../../GeneralFiltersButton/GeneralFiltersButton';

const cx = classNames.bind(S);

const SpecialistsSettings: React.FC<SpecialistSettingsProps> = (props) => {
  const { onChange, onChangeExperience, settings, onExperienceBlur, onClear, isProfilesViewerMode } = props;
  const inputRef = useRef<Input>(null);

  const [ specifiedExperiences, changeExpFilters ] = useState<(ExperienceFilterNames | null)[]>([ ExperienceFilterNames.notSet ]);
  const [ isCollapsed, setIsCollapsed ] = useState(false);

  const specifyExperienceFilter = (filterKey: ExperienceFilterNames) => {
    if (specifiedExperiences.length === Object.keys(EXPERIENCE_FILTERS).length) return; // no available filters left

    changeExpFilters([ ...specifiedExperiences, filterKey ]);
  };

  const removeExperienceFilter = (filterKey: ExperienceFilterNames | number) => {
    if (_.isNumber(filterKey)) {
      changeExpFilters([ ...specifiedExperiences.filter((f, i) => i !== filterKey) ]);
    } else {
      const keysToRemove = _.reduce(settings.skills, (acc, v, key) => {
        if (filterKey === v.experience) {
          return [ ...acc, key ];
        }

        return acc;
      }, [] as string[]);

      const updatedExpSkills: SpecialistsSkills = _.reduce(settings.skills, (acc, v, key) => {
        if (_.includes(keysToRemove, key)) {
          return acc;
        }

        return {
          ...acc,
          [key]: v,
        };
      }, {});

      const updatedExpKeys = _.filter(specifiedExperiences, f => f !== filterKey);

      changeExpFilters(updatedExpKeys);
      onChangeExperience(updatedExpSkills);
      onExperienceBlur(updatedExpSkills);
    }
  };

  const renderOccupationSelect = () => {
    const occupationOptions = _.map(OCCUPATIONS, (value, key) => <Select.Option key={key} value={key}>{value.name}</Select.Option>);

    const options = [
      <Select.Option key="Any position" value="">Any position</Select.Option>,
      ...occupationOptions,
    ];

    const onChangeOccupation = (value: string) => {
      onChange({ occupation: value });
    };

    return (
      <Select
        value={settings.occupation}
        defaultValue=""
        dropdownClassName={selects.selectDropdown}
        className={cx(selects.qsSelect, 'specialists-settings_select')}
        onChange={onChangeOccupation}
        suffixIcon={<SelectIcon />}
        size="large"
        bordered={false}
        placeholder="Specify position..."
      >
        {options}
      </Select>
    );
  };

  const renderKnowledgeSelect = () => {
    const options = _.map(ENGLISH_KNOWLEDGE_STATES, v => <Select.Option key={v.knowledgeId} value={v.knowledgeId}>{v.secondName || v.name}</Select.Option>);

    const onChangeKnowledge = (value: string) => {
      onChange({ englishKnowledge: value });
    };

    return (
      <Select
        value={settings.englishKnowledge}
        dropdownClassName={selects.selectDropdown}
        className={cx(selects.qsSelect, 'specialists-settings_select')}
        onChange={onChangeKnowledge}
        suffixIcon={<SelectIcon />}
        size="large"
        placeholder="Specify English knowledge..."
        bordered={false}
      >
        {options}
      </Select>
    );
  };

  const renderSearchInput = () => {
    const onChangeInput = _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ contains: event.target.value });
    }, 1000);

    return (
      <Input
        ref={inputRef}
        className={cx(inputs.qsInput, 'specialists-settings_input')}
        prefix={<SearchIcon style={{ fill: '#CCCCCC' }} />}
        size="large"
        onChange={onChangeInput}
        allowClear
        defaultValue={settings.contains}
        placeholder="Search by name..."
      />
    );
  };

  const renderClearAll = () => {
    const disabled = _.isEmpty(settings.skills)
      && (settings.englishKnowledge === EnglishLevelEnum.Zero)
      && _.isUndefined(settings.contains)
      && _.isUndefined(settings.occupation);

    const onClickClear = () => {
      onClear();
      changeExpFilters([ ExperienceFilterNames.notSet ]);
      if (inputRef && inputRef.current) {
        inputRef.current.setValue('');
      }
    };

    return (
      <Button
        type="text"
        size="large"
        className={cx('clear-button')}
        onClick={onClickClear}
        disabled={disabled}
      >
        <div className={buttons.iconTextWrapper}>
          <CloseCross className={cx('clear-button_icon', { disabled })} />
          <span>Clear Filters</span>
        </div>
      </Button>
    );
  };

  const renderCollapseButton = () => (
    <Button
      type="text"
      className={cx('collapse-button')}
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <Space>
        {isCollapsed ? <ShowMore className={cx('collapse-icon')} /> : <ShowLess className={cx('collapse-icon')} /> }
        <span>{ isCollapsed ? 'Expand' : 'Collapse' }</span>
      </Space>
    </Button>
  );

  const renderAllExperienceFields = () => specifiedExperiences.map((filterKey, index) => renderExperienceField(filterKey, index));

  const renderExperienceField = (currentExpFilterKey: ExperienceFilterNames | null, fieldIndex: number) => (
    <ExperienceField
      key={currentExpFilterKey || fieldIndex}
      specifiedExperiences={specifiedExperiences}
      currentExpFilterKey={currentExpFilterKey}
      fieldIndex={fieldIndex}
      settings={settings}
      onExperienceBlur={onExperienceBlur}
      removeExperienceFilter={removeExperienceFilter}
      onChangeExperience={onChangeExperience}
      changeExpFilters={changeExpFilters}
    />
  );

  const renderCollapsedSkills = () => {
    const skills = _.map(settings.skills, s => <SkillTag title={s.name} key={s.name} color={SKILL_EXPERIENCES_COLOR[s.experience]} />);

    const englishTitles: ConstantType<string> = {
      ...ENGLISH_KNOWLEDGE_TITLE,
      Zero: 'Any',
    };

    return (
      <Space split={<div className={cx('splitter')} />}>
        <div className={cx('collapsed-position')}>
          {OCCUPATIONS[settings.occupation as keyof EmployeeOccupationEnum]?.name || 'Any position'}
        </div>
        <div className={cx('collapsed-skills', { empty: _.isEmpty(skills) })}>
          {_.isEmpty(settings.skills) ? 'No selected skill tags' : skills}
        </div>
        <Space className={cx('collapsed-english')} align="center">
          <EngKnowledgeLevel englishKnowledgeLevel={settings.englishKnowledge} zeroEnglishPlaceholder={<EnglishIcon viewBox="0 0 24 19" />} />
          <span>{ settings.englishKnowledge && englishTitles[settings.englishKnowledge] }</span>
        </Space>
      </Space>
    );
  };

  const renderSpecifyExpButton = () => {
    const lastExpDone = specifiedExperiences.length !== Object.keys(ExperienceFilterNames).length;

    if (!lastExpDone) {
      return;
    }

    return (
      <SpecifyExpButton
        specifiedExperiences={specifiedExperiences}
        specifyExperienceFilter={specifyExperienceFilter}
      />
    );
  };

  const renderExpandedSettings = () => (
    <>
      <div className={cx('specialists-settings_filters')}>
        {renderOccupationSelect()}
        <Space>
          <GeneralFiltersButton
            isProfilesViewerMode={isProfilesViewerMode}
            buttonClassName={S.generalFiltersButton}
            onSearch={ids => onChange(ids)}
          />

          {renderKnowledgeSelect()}
          {renderSearchInput()}
          {renderClearAll()}
          {renderCollapseButton()}
        </Space>
      </div>

      <div className={cx('specialists-settings_experience')}>
        <div className={cx('specialists-settings_experience-inner')}>
          {renderAllExperienceFields()}
        </div>
        { renderSpecifyExpButton() }
      </div>
    </>
  );

  const renderCollapsedSettings = () => (
    <div className={cx('specialists-settings_filters', { collapsed: isCollapsed })}>
      {renderCollapsedSkills()}
      {renderCollapseButton()}
    </div>
  );

  return (
    <div className={cx('specialists-settings', { collapsed: isCollapsed })}>
      { isCollapsed ? renderCollapsedSettings() : renderExpandedSettings() }
    </div>
  );
};

export default SpecialistsSettings;
