import _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

import { Day, DayStatuses, Month, StoreDestinationType } from 'api/BusinessCalendar/types';
import { getCalendarByMonth } from 'api/BusinessCalendar/requests';
import { isDayOff } from 'api/BusinessCalendar/helpers';
import { DEFAULT_DATE_FORMAT } from 'helpers/constants/_common/timeFormats';

import { compareDayCoords } from '../components/Month/helpers/helpers';
import { CalendarDay, DayType } from './types';

interface SortedDays {
  prevMonthDays: CalendarDay[];
  currentMonthDays: CalendarDay[];
  nextMonthDays: CalendarDay[];
}

export const getFilteredDaysByMonthNumber = async (currentYearNumber: number, currentMonthNumber: number, calendar: Month[]) => {
  const monthIndex = currentMonthNumber - 1;
  const currentMonth = calendar[monthIndex];
  const prevMonth = calendar[monthIndex - 1] || await getCalendarByMonth(currentYearNumber - 1, 12);
  const nextMonth = calendar[monthIndex + 1] || await getCalendarByMonth(currentYearNumber + 1, 1);
  const allDays = [ ...prevMonth.days, ...currentMonth.days, ...nextMonth.days ];
  const isNotJanuary = currentMonthNumber === 12 ? nextMonth : null;
  const additionalMonthToStore = currentMonthNumber === 1 ? prevMonth : isNotJanuary;

  const sortedDays: SortedDays = _.reduce(allDays, (result: SortedDays, dayObj: Day) => {
    const dayjsObj = dayjs(dayObj.day);
    const trulyCoords = {
      year: currentYearNumber,
      month: currentMonthNumber,
    };    
    const destination: StoreDestinationType = compareDayCoords(dayjsObj, trulyCoords);
    const daysInMonth = dayjsObj.daysInMonth();
    const currentDateNumber = dayjsObj.get('date');
    const disabledStatus = destination === 'prevMonthDays' ? currentDateNumber < daysInMonth - 4 : currentDateNumber > 5;

    return {
      ...result,
      [destination]: [ ...result[destination], {
        ...dayObj,
        disabled: destination !== 'currentMonthDays' && disabledStatus,
        dayType: _.includes(currentMonth.days, dayObj) ? DayType.Original : DayType.Cut,
      } ],
    };
  }, {
    prevMonthDays: [],
    currentMonthDays: [],
    nextMonthDays: [],
  });

  const prevMonthToCompare = (monthIndex - 1) === -1 ? 11 : monthIndex - 1;
  const nextMonthToCompare = (monthIndex + 1) === 12 ? 0 : monthIndex + 1;

  return {
    drawerData: {
      prevMonthDays: sortedDays.prevMonthDays.filter(day => dayjs(day.day).get('month') === prevMonthToCompare),
      currentMonthPeriod: { ...currentMonth, days: sortedDays.currentMonthDays },
      nextMonthDays: sortedDays.nextMonthDays.filter(day => dayjs(day.day).get('month') === nextMonthToCompare),
    },
    additionalMonth: additionalMonthToStore, 
  };
};

export const getValidNewStatus = (oldStatus: DayStatuses, date: string) => {
  switch (oldStatus) {
    case DayStatuses.Regular:
         return DayStatuses.Holiday;
    case DayStatuses.Holiday:
      return DayStatuses.DayOff;
    case DayStatuses.DayOff:
      return DayStatuses.Regular;
  }
};

export const getDefaultMonth = (dayjsObj: Dayjs, dayType: DayType) => ({
  month: dayjsObj.get('month'),
  year: dayjsObj.get('year'),
  days: Array(dayjsObj.daysInMonth()).fill(null).map((e, i) => {
    const month = dayjsObj.get('month');
    const day = dayjs(`${dayjsObj.get('year')}-${month}-${i + 1}`).format(DEFAULT_DATE_FORMAT);
    return {
      businessDayId: null,
      day,
      dayType,
      status: isDayOff(day) ? DayStatuses.DayOff : DayStatuses.Regular,
    };
  }),
  businessMonthId: null,
} as Month);
